@tailwind base;
@tailwind components;
@tailwind utilities;

/* @import "~antd/dist/antd.css"; */
html {
  /* font-family: "Noto Naskh Arabic", serif; */
  height: 100%;
}

.card-frame {
  width: 100%;
  height: 30px;
}

.en-font {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  line-height: 1.5;
}

#apple_wallet .StripeElement {
  width: 100%;
}

.ReactModal__Overlay {
  outline: 0;
}
.l-d {
  direction: ltr;
}
.create-address,
.max-460,
.ReactModal__Content,
.ReactModal__Overlay,
.size-app,
.system-container,
.v--modal-box.modalSlideUp {
  outline: 0;
}

input[type="text"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.pb46 {
  padding-bottom: 46.5%;
}
body {
  font-size: 13px;
  color: #464646;
}
.option-list {
  border-bottom: 1px solid #f5f5f5;
}
.input-map {
  white-space: nowrap;
  overflow: hidden;
  /* text-overflow: ellipsis; */
}
.option-list:last-child {
  border-bottom: 0px solid #f5f5f5;
}
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background-color: #dfe2e7;
}
::-webkit-scrollbar-track {
  background-color: transparent;
}
.content-area {
  width: calc(100% - 135px);
}
.verify-input {
  width: 100%;
}
.checkbox-brand {
  accent-color: var(--brand-base-primary);
}
.map-height {
  height: calc(var(--app-height) - 164px);
}
.map-height1 {
  height: calc(var(--app-height) - 116px);
}
.height100vh {
  height: 100vh;
}
@media (max-width: 359px) {
  .google_locateme_btn {
    display: none;
  }
  .height100vh {
    height: auto;
  }
}
.truncate-line-1 {
  -webkit-line-clamp: 1;
  display: block;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.truncate-line-2 {
  -webkit-line-clamp: 2;
  display: block;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.cuisine ul li::before {
  content: "•";
  color: var(--color-primary);
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.cuisine ul li::before {
  content: "•";
  color: var(--color-primary);
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.cuisine ul li:first-child:before {
  content: none;
}
svg {
  /* color: #4c91ff; */
}

#root {
  display: flex;
  align-items: stretch;
  min-height: 100%;
}
body {
  display: flex;
  justify-content: center;
}
#__layout,
#root,
body {
  display: flex;
  align-items: stretch;
  min-height: 100%;
  justify-content: center;
}
#__layout,
#root {
  width: 100%;
}
.system-container {
  position: relative;
  background-color: var(--color-white);
  display: flex;
}
.size-app,
.system-container,
#root .ReactModal__Overlay,
#root .ReactModal__Content,
.v--modal-box.modalSlideUp,
.create-address,
.max-460 {
  width: 100%;
  min-width: 300px;
  max-width: 460px;
}
.checkout-page,
.basket-wrapper,
.store-page {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}
.system-container > div {
  width: 100%;
}
.atom-safe-area {
  min-height: 100%;
  width: 100%;
  display: flex;
}

@media (min-width: 401px) {
  .system-container {
    border-right: 1px solid #dfe2e7;
    border-left: 1px solid #dfe2e7;
  }
  .border-sc {
    border-right: 1px solid #dfe2e7;
  }
  .border-sc-l {
    border-left: 1px solid #dfe2e7;
  }
}

.checkout-page,
.order-page,
.profile-page,
.account-detail-page,
.brand-page,
.register-page,
.verify-page {
  /* min-height:100vh;*/
}
.z-40 {
  z-index: 40;
}
.z-19 {
  z-index: 19;
}
[dir="ltr"] .search span {
  border-top-left-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
  border-right-width: 0px;
}
[dir="ltr"] .search input {
  border-top-right-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
  border-left-width: 0px;
}
[dir="rtl"] .search span {
  border-top-right-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
  border-left-width: 0px;
}
[dir="rtl"] .search input {
  border-top-left-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
  border-right-width: 0px;
}
[dir="ltr"] .setmap {
  top: 16px;
  right: 30px;
}
[dir="rtl"] .setmap {
  top: 16px;
  left: 30px;
}

.bg-fill {
  fill: #4c91ff;
}
.modal-mask {
  background-color: rgba(0, 0, 0, 0.5);
}

.checkmark {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  display: block;
  margin: 0 auto;
  strokewidth: 2;
  stroke: #fff;
  stroke-miterlimit: 10;
  box-shadow: inset 0 0 0 #24d889;
  animation: fill-green 0.4s ease-in-out 0.4s forwards,
    scale 0.3s ease-in-out 0.9s both;
}
.checkmark circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  strokewidth: 2;
  stroke-miterlimit: 10;
  stroke: #24d889;
  fill: none;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}
.checkmark path {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}
.success-order__share {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1.75rem 1.25rem;
  box-shadow: 0 -5px 30px rgb(0 0 0 / 5%);
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  height: 100%;
  margin-bottom: 60px;
}
.status--checked {
  left: 1px;
  top: 50%;
  z-index: 1;
  transform: translateY(-50%);
  box-shadow: 0 0 0 3pxvar (--color-white);
  position: absolute;
  display: inline-block;
  border-radius: 9999px;
  z-index: 10;
}
.status--checked:before {
  width: 10px;
  transform: rotate(-45deg) translate(-2px, 10px);
}
.status--checked:after,
.status--checked:before {
  content: "";
  height: 2px;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 1rem;
  background-color: #fff;
}
.uncheck::after,
.uncheck::before {
  background-color: transparent !important;
}

.status--checked:after {
  width: 6px;
  transform: rotate(45deg) translate(9px, 5px);
}
.status {
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 2.5rem;
}
.status-line:after {
  content: "";
  top: 50%;
  left: 10px;
  position: absolute;
  height: 100%;
  border-left: 2px var(--brand-base-primary);
  border-style: dashed;
}

.status--active:before {
  content: "";
  left: 1px;
  box-shadow: 0 0 0 2px var(--color-white);
  position: absolute;
  width: 1.25rem;
  height: 1.25rem;
  border-width: 2px;
  --border-opacity: 1;
  border-color: #cbd5e0;
  border-color: rgba(203, 213, 224, var(--border-opacity));
  border-radius: 9999px;
  background-color: var(--color-white);
  z-index: 50;
}
.status--active .status--checked {
  box-shadow: inset 0 0 0 2px #fff;
}
.locateme {
  position: absolute;
  left: 0px;
  top: 0px;
  opacity: 0.4;
  width: 100%;
  height: 100vh;
  z-index: 9999;
  /* background: rgba(0, 0, 0, 0.5); */

  /* url("https://upload.wikimedia.org/wikipedia/commons/thumb/e/e5/Phi_fenomeni.gif/50px-Phi_fenomeni.gif")
    50% 50% no-repeat; */
}
.zindex-999 {
  z-index: 999999999;
}
.marker {
  position: absolute;
  height: 70px;
  width: 70px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

.checkmark-cross {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  display: block;
  margin: 0 auto;
  strokewidth: 2;
  stroke: #fff;
  stroke-miterlimit: 10;
  box-shadow: inset 0 0 0 #d82424;
  animation: fill-red 0.4s ease-in-out 0.4s forwards,
    scale 0.3s ease-in-out 0.9s both;
}
.checkmark-cross circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  strokewidth: 2;
  stroke-miterlimit: 10;
  stroke: #d82424;
  fill: none;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}
.checkmark-cross path {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes fill-red {
  100% {
    box-shadow: inset 0 0 0 60px #d82424;
  }
}
@keyframes fill-green {
  100% {
    box-shadow: inset 0 0 0 60px #24d889;
  }
}
@keyframes scale {
  0%,
  100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}
@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}
.slick-dots {
  bottom: -40px;
}
.preview-panel_image img {
  display: inline-block;
  max-height: 100%;
  max-width: 100%;
  vertical-align: middle;
  /* width: auto;
  height: auto; */
}
.slick-dots li button:before {
  font-size: 10px;
}
/* .slick-dots li {
  margin: 0px;
} */
/* .ant-carousel .slick-dots {
  margin: 0px !important;
}
.ant-carousel .slick-dots li button::after {
  position: unset !important;
  top: unset !important;
  right: unset !important;
  bottom: unset !important;
  left: unset !important;
  content: unset !important;
}
.ant-carousel .slick-dots li.slick-active button,
.ant-carousel .slick-dots li button {
  background: transparent !important;
}
.ant-carousel .slick-dots-bottom {
  bottom: -15px !important;
}
.ant-image-preview-wrap {
  z-index: 2160 !important;
}
.ant-image-preview-operations-operation {
  z-index: 2171 !important;
}
.ant-image-preview-operations-wrapper {
  height: 48px !important;
}
.ant-image-preview-mask {
  background-color: rgba(0, 0, 0, 0.9) !important;
} */
/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
body {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* footer */
#footer {
  font-size: 14px;
  padding-bottom: 1.5rem;
}

#footer .ft-head {
  font-size: 20px;
  margin-bottom: 7px;
  font-weight: 600;
}

#footer li {
  padding: 5px 0;
}
.disabled-button {
  opacity: 1 !important;
}

#footer .ft-contact li {
  padding: 2px 0;
  display: flex;
}

#footer .ft-contact li .svg-icon {
  margin-right: 5px;
}
.coupon-sec {
  -webkit-font-smoothing: antialiased;
  scrollbar-width: none;
  -ms-overflow-style: none;
  outline: none !important;
}

.coupon-sec ::-webkit-scrollbar {
  display: none;
}
/* login formkky */
.intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-4 .selected-flag {
  height: 100% !important;
}
.grow {
  flex-grow: 1;
}
.intl-tel-input.allow-dropdown.separate-dial-code .selected-flag {
  background-color: rgba(0, 0, 0, 0.03);
  display: flex !important;
}
.intl-tel-input.allow-dropdown.separate-dial-code .selected-dial-code {
  padding-left: 5px !important;
}
.intl-tel-input {
  border-radius: 0.375rem;
  margin-top: 0.5rem;
  --tw-border-opacity: 1;
  border: 1px solid;
  border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  font-size: 16px;
  width: 100%;
}
.save-address-listing {
  max-height: calc(100vh - 240px);
}
.intl-input-box {
  padding-top: 10px;
  padding-bottom: 10px;
  width: 100%;
}
.intl-input-box::placeholder {
  font-size: 0.9rem;
}
.overflow-wrap-anywhere {
  overflow-wrap: anywhere;
}
.scr-area-set {
  height: calc(100% - 60px);
  overflow-y: auto;
}
.intl-tel-input .flag-container {
  padding: 0 !important;
}
.selected-dial-code,
.intl-tel-input .arrow {
  pointer-events: none;
}
.wfa {
  height: -webkit-fill-available;
}

.iti-mobile .intl-tel-input.iti-container {
  top: 30px;
  bottom: 30px;
  left: 0px !important;
  right: 0px !important;
  position: fixed;
}
.max-h-60p {
  max-height: 60%;
}

/* BlinkingHeaderd.cs*/
@keyframes blink {
  0% {
    visibility: visible;
  }
  50% {
    visibility: hidden;
  }
  100% {
    visibility: visible;
  }
}

.blinking-login-header {
  animation: blink 2s step-start infinite;
}

.coupon-code {
  -webkit-line-clamp: 2;
  display: block;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
}
.flex1 {
  flex: 1;
  text-align: end;
}
.grow {
  flex-grow: 1;
}
.coupon-list {
  height: calc(var(--app-height) - 348px);
}

.google_locateme_btn {
  position: absolute;
  bottom: 30px;
  right: 15px;
  background: white;
  padding: 4px 8px;
  border: 1px solid #ccc;
  border-radius: 25px;
}

.star {
  cursor: pointer;
  font-size: 24px;
  color: #ccc;
}

.star.selected {
  color: #ffac33; /* Color for selected stars */
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.w-fit {
  width: fit-content;
}
.animate-fadeIn {
  animation: fadeIn 0.5s ease-in-out;
}
.gmnoprint {
  top: 40px !important;
}

.pt17 {
  padding-top: 17px;
}
.fix-detail {
  height: 100px;
  overflow: hidden;
}

.iiii {
  line-height: 8rem;
  height: 8rem;
  width: 8rem;
  display: block;
}
.iiii img {
  display: inline-block;
  max-height: 100%;
  max-width: 100%;
  vertical-align: middle;
}

/** --- */
.google-input-txt ::placeholder {
  color: rgba(239, 68, 68, 1);
  animation: blinkMe 2s linear infinite;
}
.google-input-txt ::-ms-input-placeholder {
  color: rgba(239, 68, 68, 1);
  animation: blinkMe 2s linear infinite;
}
.red-clr {
  color: rgba(239, 68, 68, 1);
}
@keyframes blinkMe {
  50% {
    opacity: 0;
  }
}
/*kknn*/
